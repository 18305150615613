import React, { FC } from 'react';

import cn from 'classnames';

import styles from './Box.module.scss';

export type BoxProps = {
  className?: string;
  marginBottomDefault?: boolean;
  flexStretched?: boolean;
}

const Box: FC<BoxProps> = ({ children, className, marginBottomDefault,flexStretched }) => (
  <div className={cn(styles.component, marginBottomDefault && styles.marginBottomDefault, flexStretched && styles.flexStretched, className)}>{children}</div>
);

export default Box;
