import * as yup from 'yup';
import { OptionalFeatures } from 'components/types';



export type OrganizationDTO = {
  address: string;
  country?: string;
  phone?: string;
  workHours?: string;
  employeesCount: number;
  employeesHasScheduleCount:number;
  employeesHasServicesCount:number;
  optionalFeatures: OptionalFeatures[]
};

export const yupSchemeOrganizationDTO = {
  name: yup.string().required(),
  address: yup.string().nullable(),

  // workingHoursData: '',
  // I need property workingHoursData that will consist of object with two string propert start and end
  workingHoursData: yup.object().shape({
    start: yup.string().required(),
    end: yup.string().required(),
  }).nullable(),
  phones: yup.array().of(yup.string().nullable()),
  image: yup.string().nullable(),
  // please help me to create yup schema for this field
  // it is array with objects {type: string, data: string}
  gallery: yup.array().of(yup.object().shape({
    type: yup.string().required(),
    // data: yup.string(),
  })),
  currency: yup.string().required(),
  // gallery: yup.array().of(yup.string().nullable()),
}

export const yupSchemeOrganizationDTOAdminMode = {
  ...yupSchemeOrganizationDTO,
  userId: yup.string(),
}

export const yupOrganizationDTO = yup
  .object(yupSchemeOrganizationDTO)
  .required();

export const yupOrganizationDTOAdminMode= yup
  .object(yupSchemeOrganizationDTO)
  .required();
